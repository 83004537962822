<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">回收管理</a></li>
            <li class="breadcrumb-item active" id="title3">回收详情</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">回收详情</h4>
      </div>
    </div>
    <el-form :model="formData" label-width="20%" align="left">
      <el-form-item label="订单号">
        {{ formData.rno }}</el-form-item>
      <el-form-item label="物品名称">
        {{ formData.rname }}</el-form-item>
      <el-form-item label="重量kg">
        {{ formData.rweight }}</el-form-item>
      <el-form-item label="具体描述">
        {{ formData.rdetail }}</el-form-item>
      <el-form-item label="用户ID">
        {{ formData.mid }}</el-form-item>
      <el-form-item label="姓名">
        {{ formData.uname }}</el-form-item>
      <el-form-item label="手机号码">
        {{ formData.uphone }}</el-form-item>
      <el-form-item label="地址">
        {{ formData.address }}</el-form-item>
      <el-form-item label="回收时间">
        {{ formData.rtime }}</el-form-item>
      <el-form-item label="照片">
        <block v-for="item in formData.photoList" :key="item.id">
          <img :src="'https://www.jiuyihuishou.online/useclothingrecycling/' + item.addr"
            style="width: 150px;height: 150px; padding: 15px;" />
        </block>

      </el-form-item>
      <el-form-item label="提交时间">
        {{ formData.rsubtime }}</el-form-item>
      <el-form-item label="订单状态">
        {{ formData.rstatus }}</el-form-item>
      <el-form-item label="回收碳积分">
        {{ formData.rmoney }}</el-form-item>
      <el-form-item label="奖励积分">
        {{ formData.rpoint }}</el-form-item>
      <el-form-item>
        <el-button type="info" size="small" @click="back" icon="el-icon-back">返 回</el-button>
      </el-form-item>
    </el-form>


  </div>
</template>
<script>

import request, { base } from "../../../utils/http";
export default {
  name: 'RecyclesDetail',
  components: {
  },
  data() {
    return {
      id: '',
      formData: {}, //表单数据         

    };
  },
  created() {
    this.id = this.$route.query.id; //获取参数
    this.getDatas();
  },


  methods: {

    //获取列表数据
    getDatas() {
      let para = {
      };
      this.listLoading = true;
      let url = base + "/recycles/get?id=" + this.id;
      request.post(url, para).then((res) => {
        this.formData = JSON.parse(JSON.stringify(res.resdata));
        this.listLoading = false;
      });
    },

    // 返回
    back() {
      //返回上一页
      this.$router.go(-1);
    },

  },
}

</script>
<style scoped></style>
 

